// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts,import_extension=none"
// @generated from file svc/frontend/portal/proto/api/api.proto (package api, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetSessionRequest, GetSessionResponse } from "./api_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * PortalFrontendAPI implements Portal Frontend API specific RPCs.
 *
 * @generated from service api.PortalFrontendAPI
 */
export const PortalFrontendAPI = {
  typeName: "api.PortalFrontendAPI",
  methods: {
    /**
     * GetSession returns extra information about the current authenticated agent.
     *
     * @generated from rpc api.PortalFrontendAPI.GetSession
     */
    getSession: {
      name: "GetSession",
      I: GetSessionRequest,
      O: GetSessionResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

