// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file pkg/proto/policy/policy.proto (package proto.policy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum proto.policy.InsuranceType
 */
export enum InsuranceType {
  /**
   * @generated from enum value: UNDEFINED_INSURANCE_TYPE = 0;
   */
  UNDEFINED_INSURANCE_TYPE = 0,

  /**
   * @generated from enum value: PERSONAL_AUTO = 1;
   */
  PERSONAL_AUTO = 1,

  /**
   * @generated from enum value: PERSONAL_UMBRELLA = 2;
   */
  PERSONAL_UMBRELLA = 2,

  /**
   * @generated from enum value: HOMEOWNERS = 3;
   */
  HOMEOWNERS = 3,

  /**
   * @generated from enum value: RENTERS = 4;
   */
  RENTERS = 4,

  /**
   * @generated from enum value: CONDO = 5;
   */
  CONDO = 5,

  /**
   * @generated from enum value: DWELLING_FIRE = 6;
   */
  DWELLING_FIRE = 6,

  /**
   * @generated from enum value: LANDLORD = 7;
   */
  LANDLORD = 7,

  /**
   * @generated from enum value: BOAT = 8;
   */
  BOAT = 8,

  /**
   * @generated from enum value: MOTORCYCLE = 9;
   */
  MOTORCYCLE = 9,

  /**
   * @generated from enum value: FLOOD = 10;
   */
  FLOOD = 10,

  /**
   * @generated from enum value: ATV_OFF_ROAD_VEHICLE = 11;
   */
  ATV_OFF_ROAD_VEHICLE = 11,

  /**
   * @generated from enum value: MEDICARE = 12;
   */
  MEDICARE = 12,

  /**
   * @generated from enum value: PET = 13;
   */
  PET = 13,

  /**
   * @generated from enum value: SNOWMOBILE = 14;
   */
  SNOWMOBILE = 14,

  /**
   * @generated from enum value: PERSONAL_WIND = 15;
   */
  PERSONAL_WIND = 15,

  /**
   * @generated from enum value: HEALTH = 16;
   */
  HEALTH = 16,

  /**
   * @generated from enum value: EARTHQUAKE = 17;
   */
  EARTHQUAKE = 17,

  /**
   * @generated from enum value: VISION = 18;
   */
  VISION = 18,

  /**
   * @generated from enum value: SCHEDULED_PERSONAL_PROPERTY = 19;
   */
  SCHEDULED_PERSONAL_PROPERTY = 19,

  /**
   * @generated from enum value: ANTIQUE_CLASSIC_AUTO = 20;
   */
  ANTIQUE_CLASSIC_AUTO = 20,

  /**
   * @generated from enum value: RV_MOTORHOME = 21;
   */
  RV_MOTORHOME = 21,

  /**
   * @generated from enum value: DENTAL = 22;
   */
  DENTAL = 22,

  /**
   * @generated from enum value: EVENT_LIABILITY = 23;
   */
  EVENT_LIABILITY = 23,

  /**
   * @generated from enum value: IDENTITY_PROTECTION = 24;
   */
  IDENTITY_PROTECTION = 24,

  /**
   * Deprecated in favor of MANUFACTURED_HOME
   *
   * @generated from enum value: MOBILE_HOMEOWNERS = 25 [deprecated = true];
   * @deprecated
   */
  MOBILE_HOMEOWNERS = 25,

  /**
   * @generated from enum value: LIFE = 26;
   */
  LIFE = 26,

  /**
   * @generated from enum value: ELECTRONIC_DEVICES = 27;
   */
  ELECTRONIC_DEVICES = 27,

  /**
   * @generated from enum value: RIDESHARING = 28;
   */
  RIDESHARING = 28,

  /**
   * @generated from enum value: TRAVEL = 29;
   */
  TRAVEL = 29,

  /**
   * @generated from enum value: OTHER = 30;
   */
  OTHER = 30,

  /**
   * @generated from enum value: COMMERCIAL_AUTO = 31;
   */
  COMMERCIAL_AUTO = 31,

  /**
   * @generated from enum value: COMMERCIAL = 32;
   */
  COMMERCIAL = 32,

  /**
   * @generated from enum value: INLAND_MARINE = 33;
   */
  INLAND_MARINE = 33,

  /**
   * @generated from enum value: MULTILINE = 34;
   */
  MULTILINE = 34,

  /**
   * @generated from enum value: PROFESSIONAL_LIABILITY = 35;
   */
  PROFESSIONAL_LIABILITY = 35,

  /**
   * @generated from enum value: GENERAL_LIABILITY = 36;
   */
  GENERAL_LIABILITY = 36,

  /**
   * @generated from enum value: BUSINESS_OWNER_POLICY = 37;
   */
  BUSINESS_OWNER_POLICY = 37,

  /**
   * @generated from enum value: WORKERS_COMPENSATION = 38;
   */
  WORKERS_COMPENSATION = 38,

  /**
   * @generated from enum value: COMMERCIAL_PROPERTY = 39;
   */
  COMMERCIAL_PROPERTY = 39,

  /**
   * @generated from enum value: COMMERCIAL_UMBRELLA = 40;
   */
  COMMERCIAL_UMBRELLA = 40,

  /**
   * @generated from enum value: DIRECTORS_AND_OFFICERS = 41;
   */
  DIRECTORS_AND_OFFICERS = 41,

  /**
   * @generated from enum value: EMPLOYMENT_PRACTICES_LIABILITY = 42;
   */
  EMPLOYMENT_PRACTICES_LIABILITY = 42,

  /**
   * @generated from enum value: ERRORS_AND_OMISSIONS = 43;
   */
  ERRORS_AND_OMISSIONS = 43,

  /**
   * @generated from enum value: COMMERCIAL_PACKAGE = 44;
   */
  COMMERCIAL_PACKAGE = 44,

  /**
   * @generated from enum value: LIQUOR_LIABILITY = 45;
   */
  LIQUOR_LIABILITY = 45,

  /**
   * @generated from enum value: EXCESS_LIABILITY = 46;
   */
  EXCESS_LIABILITY = 46,

  /**
   * @generated from enum value: COMMERCIAL_INLAND_MARINE = 47;
   */
  COMMERCIAL_INLAND_MARINE = 47,

  /**
   * @generated from enum value: OCEAN_MARINE = 48;
   */
  OCEAN_MARINE = 48,

  /**
   * @generated from enum value: POLUTION_LIABILITY = 49;
   */
  POLUTION_LIABILITY = 49,

  /**
   * @generated from enum value: BOND = 50;
   */
  BOND = 50,

  /**
   * @generated from enum value: BOND_FIDELITY = 51;
   */
  BOND_FIDELITY = 51,

  /**
   * @generated from enum value: BOND_FIDUCIARY = 52;
   */
  BOND_FIDUCIARY = 52,

  /**
   * @generated from enum value: BOND_SURETY = 53;
   */
  BOND_SURETY = 53,

  /**
   * @generated from enum value: CRIME = 54;
   */
  CRIME = 54,

  /**
   * @generated from enum value: CYBER_LIABILITY = 55;
   */
  CYBER_LIABILITY = 55,

  /**
   * @generated from enum value: GARAGE_AND_DEALERS = 56;
   */
  GARAGE_AND_DEALERS = 56,

  /**
   * @generated from enum value: GARAGE_KEEPERS_LEGAL_LIABILITY = 57;
   */
  GARAGE_KEEPERS_LEGAL_LIABILITY = 57,

  /**
   * @generated from enum value: INSTALLATION_BUILDERS_RISK = 58;
   */
  INSTALLATION_BUILDERS_RISK = 58,

  /**
   * @generated from enum value: SHORT_TERM_DISABILITY = 59;
   */
  SHORT_TERM_DISABILITY = 59,

  /**
   * @generated from enum value: EMPLOYEE_BENEFITS_LIABILITY = 60;
   */
  EMPLOYEE_BENEFITS_LIABILITY = 60,

  /**
   * @generated from enum value: EQUIPMENT_BREAKDOWN = 61;
   */
  EQUIPMENT_BREAKDOWN = 61,

  /**
   * @generated from enum value: BUSINESS_INTERRUPTION = 62;
   */
  BUSINESS_INTERRUPTION = 62,

  /**
   * @generated from enum value: HIRED_AND_NON_OWNED_AUTO = 63;
   */
  HIRED_AND_NON_OWNED_AUTO = 63,

  /**
   * @generated from enum value: MEDICAL_MALPRACTICE = 64;
   */
  MEDICAL_MALPRACTICE = 64,

  /**
   * @generated from enum value: PRODUCT_LIABILITY = 65;
   */
  PRODUCT_LIABILITY = 65,

  /**
   * @generated from enum value: FIDUCIARY_LIABILITY = 66;
   */
  FIDUCIARY_LIABILITY = 66,

  /**
   * @generated from enum value: HABITATIONAL = 67;
   */
  HABITATIONAL = 67,

  /**
   * @generated from enum value: OWNERS_AND_CONTRACTORS_PROTECTIVE_LIABILITY = 68;
   */
  OWNERS_AND_CONTRACTORS_PROTECTIVE_LIABILITY = 68,

  /**
   * @generated from enum value: FIRE_BOILER_AND_MACHINERY = 69;
   */
  FIRE_BOILER_AND_MACHINERY = 69,

  /**
   * @generated from enum value: RENT_GUARANTEE = 70;
   */
  RENT_GUARANTEE = 70,

  /**
   * @generated from enum value: PERSONAL_PACKAGE = 71;
   */
  PERSONAL_PACKAGE = 71,

  /**
   * @generated from enum value: COMMERCIAL_FLEET = 72;
   */
  COMMERCIAL_FLEET = 72,

  /**
   * @generated from enum value: COMMERCIAL_WHOLESALE_BROKERS = 73;
   */
  COMMERCIAL_WHOLESALE_BROKERS = 73,

  /**
   * @generated from enum value: EXCESS_AND_SURPLUS_COMMERCIAL_CASUALTY = 74;
   */
  EXCESS_AND_SURPLUS_COMMERCIAL_CASUALTY = 74,

  /**
   * @generated from enum value: POWERSPORTS = 75;
   */
  POWERSPORTS = 75,

  /**
   * @generated from enum value: COLLECTORS_VEHICLE = 76;
   */
  COLLECTORS_VEHICLE = 76,

  /**
   * @generated from enum value: YACHT = 77;
   */
  YACHT = 77,

  /**
   * @generated from enum value: VACANT_HOMEOWNERS = 78;
   */
  VACANT_HOMEOWNERS = 78,

  /**
   * @generated from enum value: TRAVEL_TRAILER = 79;
   */
  TRAVEL_TRAILER = 79,

  /**
   * @generated from enum value: WATERCRAFT = 80;
   */
  WATERCRAFT = 80,

  /**
   * @generated from enum value: OFF_ROAD_VEHICLE = 81;
   */
  OFF_ROAD_VEHICLE = 81,

  /**
   * @generated from enum value: MANUFACTURED_HOME = 82;
   */
  MANUFACTURED_HOME = 82,

  /**
   * @generated from enum value: SPECIAL_EVENTS = 83;
   */
  SPECIAL_EVENTS = 83,

  /**
   * @generated from enum value: SPECIALTY_COMMERCIAL = 84;
   */
  SPECIALTY_COMMERCIAL = 84,

  /**
   * @generated from enum value: RESIDENTIAL_FLOOD = 85;
   */
  RESIDENTIAL_FLOOD = 85,

  /**
   * @generated from enum value: COMMERCIAL_FLOOD = 86;
   */
  COMMERCIAL_FLOOD = 86,

  /**
   * @generated from enum value: PARAMETRIC_EARTHQUAKE = 87;
   */
  PARAMETRIC_EARTHQUAKE = 87,

  /**
   * @generated from enum value: HOME_AND_ELECTRONICS_PROTECTION = 88;
   */
  HOME_AND_ELECTRONICS_PROTECTION = 88,

  /**
   * @generated from enum value: RECREATIONAL_VEHICLES = 89;
   */
  RECREATIONAL_VEHICLES = 89,
}
// Retrieve enum metadata with: proto3.getEnumType(InsuranceType)
proto3.util.setEnumType(InsuranceType, "proto.policy.InsuranceType", [
  { no: 0, name: "UNDEFINED_INSURANCE_TYPE" },
  { no: 1, name: "PERSONAL_AUTO" },
  { no: 2, name: "PERSONAL_UMBRELLA" },
  { no: 3, name: "HOMEOWNERS" },
  { no: 4, name: "RENTERS" },
  { no: 5, name: "CONDO" },
  { no: 6, name: "DWELLING_FIRE" },
  { no: 7, name: "LANDLORD" },
  { no: 8, name: "BOAT" },
  { no: 9, name: "MOTORCYCLE" },
  { no: 10, name: "FLOOD" },
  { no: 11, name: "ATV_OFF_ROAD_VEHICLE" },
  { no: 12, name: "MEDICARE" },
  { no: 13, name: "PET" },
  { no: 14, name: "SNOWMOBILE" },
  { no: 15, name: "PERSONAL_WIND" },
  { no: 16, name: "HEALTH" },
  { no: 17, name: "EARTHQUAKE" },
  { no: 18, name: "VISION" },
  { no: 19, name: "SCHEDULED_PERSONAL_PROPERTY" },
  { no: 20, name: "ANTIQUE_CLASSIC_AUTO" },
  { no: 21, name: "RV_MOTORHOME" },
  { no: 22, name: "DENTAL" },
  { no: 23, name: "EVENT_LIABILITY" },
  { no: 24, name: "IDENTITY_PROTECTION" },
  { no: 25, name: "MOBILE_HOMEOWNERS" },
  { no: 26, name: "LIFE" },
  { no: 27, name: "ELECTRONIC_DEVICES" },
  { no: 28, name: "RIDESHARING" },
  { no: 29, name: "TRAVEL" },
  { no: 30, name: "OTHER" },
  { no: 31, name: "COMMERCIAL_AUTO" },
  { no: 32, name: "COMMERCIAL" },
  { no: 33, name: "INLAND_MARINE" },
  { no: 34, name: "MULTILINE" },
  { no: 35, name: "PROFESSIONAL_LIABILITY" },
  { no: 36, name: "GENERAL_LIABILITY" },
  { no: 37, name: "BUSINESS_OWNER_POLICY" },
  { no: 38, name: "WORKERS_COMPENSATION" },
  { no: 39, name: "COMMERCIAL_PROPERTY" },
  { no: 40, name: "COMMERCIAL_UMBRELLA" },
  { no: 41, name: "DIRECTORS_AND_OFFICERS" },
  { no: 42, name: "EMPLOYMENT_PRACTICES_LIABILITY" },
  { no: 43, name: "ERRORS_AND_OMISSIONS" },
  { no: 44, name: "COMMERCIAL_PACKAGE" },
  { no: 45, name: "LIQUOR_LIABILITY" },
  { no: 46, name: "EXCESS_LIABILITY" },
  { no: 47, name: "COMMERCIAL_INLAND_MARINE" },
  { no: 48, name: "OCEAN_MARINE" },
  { no: 49, name: "POLUTION_LIABILITY" },
  { no: 50, name: "BOND" },
  { no: 51, name: "BOND_FIDELITY" },
  { no: 52, name: "BOND_FIDUCIARY" },
  { no: 53, name: "BOND_SURETY" },
  { no: 54, name: "CRIME" },
  { no: 55, name: "CYBER_LIABILITY" },
  { no: 56, name: "GARAGE_AND_DEALERS" },
  { no: 57, name: "GARAGE_KEEPERS_LEGAL_LIABILITY" },
  { no: 58, name: "INSTALLATION_BUILDERS_RISK" },
  { no: 59, name: "SHORT_TERM_DISABILITY" },
  { no: 60, name: "EMPLOYEE_BENEFITS_LIABILITY" },
  { no: 61, name: "EQUIPMENT_BREAKDOWN" },
  { no: 62, name: "BUSINESS_INTERRUPTION" },
  { no: 63, name: "HIRED_AND_NON_OWNED_AUTO" },
  { no: 64, name: "MEDICAL_MALPRACTICE" },
  { no: 65, name: "PRODUCT_LIABILITY" },
  { no: 66, name: "FIDUCIARY_LIABILITY" },
  { no: 67, name: "HABITATIONAL" },
  { no: 68, name: "OWNERS_AND_CONTRACTORS_PROTECTIVE_LIABILITY" },
  { no: 69, name: "FIRE_BOILER_AND_MACHINERY" },
  { no: 70, name: "RENT_GUARANTEE" },
  { no: 71, name: "PERSONAL_PACKAGE" },
  { no: 72, name: "COMMERCIAL_FLEET" },
  { no: 73, name: "COMMERCIAL_WHOLESALE_BROKERS" },
  { no: 74, name: "EXCESS_AND_SURPLUS_COMMERCIAL_CASUALTY" },
  { no: 75, name: "POWERSPORTS" },
  { no: 76, name: "COLLECTORS_VEHICLE" },
  { no: 77, name: "YACHT" },
  { no: 78, name: "VACANT_HOMEOWNERS" },
  { no: 79, name: "TRAVEL_TRAILER" },
  { no: 80, name: "WATERCRAFT" },
  { no: 81, name: "OFF_ROAD_VEHICLE" },
  { no: 82, name: "MANUFACTURED_HOME" },
  { no: 83, name: "SPECIAL_EVENTS" },
  { no: 84, name: "SPECIALTY_COMMERCIAL" },
  { no: 85, name: "RESIDENTIAL_FLOOD" },
  { no: 86, name: "COMMERCIAL_FLOOD" },
  { no: 87, name: "PARAMETRIC_EARTHQUAKE" },
  { no: 88, name: "HOME_AND_ELECTRONICS_PROTECTION" },
  { no: 89, name: "RECREATIONAL_VEHICLES" },
]);

/**
 * @generated from enum proto.policy.LineOfInsurance
 */
export enum LineOfInsurance {
  /**
   * @generated from enum value: LINE_OF_INSURANCE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: LINE_OF_INSURANCE_PERSONAL = 1;
   */
  PERSONAL = 1,

  /**
   * @generated from enum value: LINE_OF_INSURANCE_COMMERCIAL = 2;
   */
  COMMERCIAL = 2,

  /**
   * @generated from enum value: LINE_OF_INSURANCE_LIFE = 3;
   */
  LIFE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(LineOfInsurance)
proto3.util.setEnumType(LineOfInsurance, "proto.policy.LineOfInsurance", [
  { no: 0, name: "LINE_OF_INSURANCE_UNSPECIFIED" },
  { no: 1, name: "LINE_OF_INSURANCE_PERSONAL" },
  { no: 2, name: "LINE_OF_INSURANCE_COMMERCIAL" },
  { no: 3, name: "LINE_OF_INSURANCE_LIFE" },
]);

/**
 * @generated from enum proto.policy.PremiumTerm
 */
export enum PremiumTerm {
  /**
   * @generated from enum value: ANNUAL = 0;
   */
  ANNUAL = 0,

  /**
   * @generated from enum value: SIX_MONTHS = 1;
   */
  SIX_MONTHS = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(PremiumTerm)
proto3.util.setEnumType(PremiumTerm, "proto.policy.PremiumTerm", [
  { no: 0, name: "ANNUAL" },
  { no: 1, name: "SIX_MONTHS" },
]);

/**
 * PolicyComponent is a representation of the coverages and
 * deductibles of a policy.
 *
 * @generated from message proto.policy.PolicyComponent
 */
export class PolicyComponent extends Message<PolicyComponent> {
  /**
   * A unique identifier of the type of component. It is an open
   * representation because each Carrier most likely is going to have
   * its own unique codes.
   *
   * @generated from field: string code = 1;
   */
  code = "";

  /**
   * A text description of the component.
   *
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * The value(s) of the component, if any.
   *
   * @generated from field: repeated string values = 3;
   */
  values: string[] = [];

  /**
   * The type of value.
   *
   * @generated from field: proto.policy.PolicyComponent.ValueType value_type = 4;
   */
  valueType = PolicyComponent_ValueType.UNSPECIFIED;

  constructor(data?: PartialMessage<PolicyComponent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "proto.policy.PolicyComponent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "value_type", kind: "enum", T: proto3.getEnumType(PolicyComponent_ValueType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PolicyComponent {
    return new PolicyComponent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PolicyComponent {
    return new PolicyComponent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PolicyComponent {
    return new PolicyComponent().fromJsonString(jsonString, options);
  }

  static equals(a: PolicyComponent | PlainMessage<PolicyComponent> | undefined, b: PolicyComponent | PlainMessage<PolicyComponent> | undefined): boolean {
    return proto3.util.equals(PolicyComponent, a, b);
  }
}

/**
 * @generated from enum proto.policy.PolicyComponent.ValueType
 */
export enum PolicyComponent_ValueType {
  /**
   * @generated from enum value: VALUE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: VALUE_TYPE_AMOUNT = 1;
   */
  AMOUNT = 1,

  /**
   * @generated from enum value: VALUE_TYPE_PERCENTAGE = 2;
   */
  PERCENTAGE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(PolicyComponent_ValueType)
proto3.util.setEnumType(PolicyComponent_ValueType, "proto.policy.PolicyComponent.ValueType", [
  { no: 0, name: "VALUE_TYPE_UNSPECIFIED" },
  { no: 1, name: "VALUE_TYPE_AMOUNT" },
  { no: 2, name: "VALUE_TYPE_PERCENTAGE" },
]);

/**
 * @generated from message proto.policy.QuotePremium
 */
export class QuotePremium extends Message<QuotePremium> {
  /**
   * @generated from field: double amount = 1;
   */
  amount = 0;

  /**
   * @generated from field: proto.policy.PremiumTerm term = 2;
   */
  term = PremiumTerm.ANNUAL;

  constructor(data?: PartialMessage<QuotePremium>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "proto.policy.QuotePremium";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "term", kind: "enum", T: proto3.getEnumType(PremiumTerm) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuotePremium {
    return new QuotePremium().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuotePremium {
    return new QuotePremium().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuotePremium {
    return new QuotePremium().fromJsonString(jsonString, options);
  }

  static equals(a: QuotePremium | PlainMessage<QuotePremium> | undefined, b: QuotePremium | PlainMessage<QuotePremium> | undefined): boolean {
    return proto3.util.equals(QuotePremium, a, b);
  }
}

/**
 * Bundle refers to the extra quote information that
 * we get from some carriers when you get special prices if
 * you do more than one line of business in the same quote.
 * Bundle will have one of the following states:
 * 1. Not set: The carrier does not support bundling or we don't have a bundling offer for this quote
 * 2. Only the insurance_type is set: We don't have a quote for the given insurance_type, but we have a bundling offer
 * for this quote that can be used as a fallback.
 * 3. Both insurance_type and premium are set: We have a normal quote for this LOB and also have a bundle to show.
 *
 * @generated from message proto.policy.Bundle
 */
export class Bundle extends Message<Bundle> {
  /**
   * @generated from field: proto.policy.QuotePremium premium = 1;
   */
  premium?: QuotePremium;

  /**
   * @generated from field: proto.policy.InsuranceType insurance_type = 2;
   */
  insuranceType = InsuranceType.UNDEFINED_INSURANCE_TYPE;

  constructor(data?: PartialMessage<Bundle>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "proto.policy.Bundle";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "premium", kind: "message", T: QuotePremium },
    { no: 2, name: "insurance_type", kind: "enum", T: proto3.getEnumType(InsuranceType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Bundle {
    return new Bundle().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Bundle {
    return new Bundle().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Bundle {
    return new Bundle().fromJsonString(jsonString, options);
  }

  static equals(a: Bundle | PlainMessage<Bundle> | undefined, b: Bundle | PlainMessage<Bundle> | undefined): boolean {
    return proto3.util.equals(Bundle, a, b);
  }
}

