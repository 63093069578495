import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Agent, parseAgent } from '@agentero/models';

import { useAgentUniqueIdentifier } from 'packages/contexts/AgentUniqueIdentifierContext';
import { queryClient } from 'packages/services/QueryCache';
import { portalService } from 'packages/services/connectClients';

export const currentExpertResourceKey = 'current_expert';

const fetchLocalAgentSession = async (): Promise<Agent> => {
	const response = await fetch(`${process.env.NEXT_PUBLIC_LOCAL_API_URL}/current_expert`);
	const agent = await response.json();
	return agent;
};

export const fetchAgentSession = async (init?: RequestInit): Promise<Agent> => {
	if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'local') {
		return fetchLocalAgentSession();
	}

	const response = await portalService.getSession({}, { headers: init?.headers || {} });
	return parseAgent(response);
};

export const useAgentResource = () => {
	const { agentUniqueIdentifier } = useAgentUniqueIdentifier();
	return useQuery([currentExpertResourceKey, agentUniqueIdentifier], () => fetchAgentSession(), {
		cacheTime: Infinity,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		suspense: true,
		staleTime: Infinity,
		retry: false
	});
};

export const useInvalidateAgentResource = () => {
	const queryClient = useQueryClient();

	return () => queryClient.invalidateQueries([currentExpertResourceKey]);
};

export const getAgentData = () =>
	queryClient.getQueryData<Agent>(undefined, {
		exact: false,
		queryKey: [currentExpertResourceKey]
	});

export const prefetchAgent = (init: RequestInit, agentUniqueIdentifier: string) => {
	return queryClient.fetchQuery(
		[currentExpertResourceKey, agentUniqueIdentifier],
		() => fetchAgentSession(init),
		{
			staleTime: Infinity,
			retry: 1
		}
	);
};
