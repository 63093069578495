import { PropsWithChildren } from 'react';

import { Corner, Root, Scrollbar, Thumb, Viewport } from '@radix-ui/react-scroll-area';

import { css } from '@agentero/styles/css';
import { styled } from '@agentero/styles/jsx';

type ScrollAreaProps = {
	width?: string;
	height?: string;
	orientation?: 'horizontal' | 'vertical';
	viewPortRef?: React.RefObject<HTMLDivElement>;
};

const ScrollAreaRoot = styled(Root, {
	base: {
		'--scrollbar-size': '0.75rem',
		borderRadius: 'md',
		overflow: 'hidden',
		backgroundColor: 'bg',
		border: '0.0625rem solid token(colors.border)'
	}
});

const ScrollAreaViewport = styled(Viewport, {
	base: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		borderRadius: 'inherit'
	}
});

const ScrollAreaScrollbar = styled(Scrollbar, {
	base: {
		position: 'absolute',
		zIndex: 1,
		display: 'flex',
		userSelect: 'none',
		touchAction: 'none',
		padding: '2',
		background: 'transparent',
		transition: 'background 160ms ease-out',
		_hover: { background: 'transparent' },
		'&[data-orientation="vertical"]': { width: 'var(--scrollbar-size)' },
		'&[data-orientation="horizontal"]': {
			flexDirection: 'column',
			height: 'var(--scrollbar-size)'
		}
	}
});

const ScrollAreaThumb = styled(Thumb, {
	base: {
		flex: 1,
		background: 'neutral.80',
		borderRadius: 'var(--scrollbar-size)',
		position: 'relative',
		_before: {
			content: '""',
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			width: '100%',
			height: '100%',
			minWidth: '40',
			minHeight: '40',
			transition: '0.3s'
		},
		transition: 'background 0.3s'
	}
});

const ScrollAreaCorner = styled(Corner, {
	base: {
		background: 'transparent'
	}
});

export const ScrollArea = ({
	children,
	width = '100%',
	height = '100%',
	viewPortRef
}: PropsWithChildren<ScrollAreaProps>) => (
	<ScrollAreaRoot className={css({ width, height })}>
		<ScrollAreaViewport ref={viewPortRef}>{children}</ScrollAreaViewport>
		<ScrollAreaScrollbar orientation="vertical">
			<ScrollAreaThumb />
		</ScrollAreaScrollbar>
		<ScrollAreaScrollbar orientation="horizontal">
			<ScrollAreaThumb />
		</ScrollAreaScrollbar>
		<ScrollAreaCorner />
	</ScrollAreaRoot>
);
