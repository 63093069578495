// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file pkg/proto/contact/contact.proto (package proto.contact, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from enum proto.contact.ContactLineType
 */
export enum ContactLineType {
  /**
   * @generated from enum value: PERSONAL = 0;
   */
  PERSONAL = 0,

  /**
   * @generated from enum value: COMMERCIAL = 1;
   */
  COMMERCIAL = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ContactLineType)
proto3.util.setEnumType(ContactLineType, "proto.contact.ContactLineType", [
  { no: 0, name: "PERSONAL" },
  { no: 1, name: "COMMERCIAL" },
]);

/**
 * @generated from enum proto.contact.ContactSource
 */
export enum ContactSource {
  /**
   * @generated from enum value: UNDEFINED_CONTACT_SOURCE = 0;
   */
  UNDEFINED_CONTACT_SOURCE = 0,

  /**
   * @generated from enum value: INDEPENDENT = 1;
   */
  INDEPENDENT = 1,

  /**
   * @generated from enum value: REFERRED = 2;
   */
  REFERRED = 2,

  /**
   * @generated from enum value: MARKETING = 3;
   */
  MARKETING = 3,

  /**
   * @generated from enum value: ENTERED = 4;
   */
  ENTERED = 4,

  /**
   * @generated from enum value: IMPORTED = 5;
   */
  IMPORTED = 5,

  /**
   * @generated from enum value: PURCHASED = 6;
   */
  PURCHASED = 6,

  /**
   * @generated from enum value: ZAPIER = 7;
   */
  ZAPIER = 7,

  /**
   * @generated from enum value: QUOTE = 8;
   */
  QUOTE = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(ContactSource)
proto3.util.setEnumType(ContactSource, "proto.contact.ContactSource", [
  { no: 0, name: "UNDEFINED_CONTACT_SOURCE" },
  { no: 1, name: "INDEPENDENT" },
  { no: 2, name: "REFERRED" },
  { no: 3, name: "MARKETING" },
  { no: 4, name: "ENTERED" },
  { no: 5, name: "IMPORTED" },
  { no: 6, name: "PURCHASED" },
  { no: 7, name: "ZAPIER" },
  { no: 8, name: "QUOTE" },
]);

/**
 * @generated from message proto.contact.Address
 */
export class Address extends Message<Address> {
  /**
   * @generated from field: string street = 1;
   */
  street = "";

  /**
   * @generated from field: string zip_code = 2;
   */
  zipCode = "";

  /**
   * @generated from field: string city = 3;
   */
  city = "";

  /**
   * @generated from field: string state = 4;
   */
  state = "";

  /**
   * @generated from field: string unit = 5;
   */
  unit = "";

  constructor(data?: PartialMessage<Address>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "proto.contact.Address";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "street", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "unit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Address {
    return new Address().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Address {
    return new Address().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Address {
    return new Address().fromJsonString(jsonString, options);
  }

  static equals(a: Address | PlainMessage<Address> | undefined, b: Address | PlainMessage<Address> | undefined): boolean {
    return proto3.util.equals(Address, a, b);
  }
}

/**
 * @generated from message proto.contact.CommercialPrimaryContact
 */
export class CommercialPrimaryContact extends Message<CommercialPrimaryContact> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 2;
   */
  lastName = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string phone_number = 4;
   */
  phoneNumber = "";

  constructor(data?: PartialMessage<CommercialPrimaryContact>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "proto.contact.CommercialPrimaryContact";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CommercialPrimaryContact {
    return new CommercialPrimaryContact().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CommercialPrimaryContact {
    return new CommercialPrimaryContact().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CommercialPrimaryContact {
    return new CommercialPrimaryContact().fromJsonString(jsonString, options);
  }

  static equals(a: CommercialPrimaryContact | PlainMessage<CommercialPrimaryContact> | undefined, b: CommercialPrimaryContact | PlainMessage<CommercialPrimaryContact> | undefined): boolean {
    return proto3.util.equals(CommercialPrimaryContact, a, b);
  }
}

/**
 * @generated from message proto.contact.CommercialCompanyInfo
 */
export class CommercialCompanyInfo extends Message<CommercialCompanyInfo> {
  /**
   * @generated from field: string company_name = 1;
   */
  companyName = "";

  /**
   * @generated from field: string website = 2;
   */
  website = "";

  /**
   * @generated from field: string business_description = 4;
   */
  businessDescription = "";

  /**
   * @generated from field: proto.contact.CommercialCompanyInfo.LegalEntityType legal_entity_type = 5;
   */
  legalEntityType = CommercialCompanyInfo_LegalEntityType.UNDEFINED_LEGAL_ENTITY_TYPE;

  /**
   * @generated from field: string fein = 6;
   */
  fein = "";

  /**
   * @generated from field: string naics_code = 7;
   */
  naicsCode = "";

  /**
   * @generated from field: string year_business_founded = 8;
   */
  yearBusinessFounded = "";

  /**
   * @generated from field: int64 number_of_employees = 9;
   */
  numberOfEmployees = protoInt64.zero;

  /**
   * @generated from field: float payroll = 10;
   */
  payroll = 0;

  /**
   * @generated from field: float revenue = 11;
   */
  revenue = 0;

  constructor(data?: PartialMessage<CommercialCompanyInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "proto.contact.CommercialCompanyInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "website", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "business_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "legal_entity_type", kind: "enum", T: proto3.getEnumType(CommercialCompanyInfo_LegalEntityType) },
    { no: 6, name: "fein", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "naics_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "year_business_founded", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "number_of_employees", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "payroll", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "revenue", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CommercialCompanyInfo {
    return new CommercialCompanyInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CommercialCompanyInfo {
    return new CommercialCompanyInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CommercialCompanyInfo {
    return new CommercialCompanyInfo().fromJsonString(jsonString, options);
  }

  static equals(a: CommercialCompanyInfo | PlainMessage<CommercialCompanyInfo> | undefined, b: CommercialCompanyInfo | PlainMessage<CommercialCompanyInfo> | undefined): boolean {
    return proto3.util.equals(CommercialCompanyInfo, a, b);
  }
}

/**
 * @generated from enum proto.contact.CommercialCompanyInfo.LegalEntityType
 */
export enum CommercialCompanyInfo_LegalEntityType {
  /**
   * @generated from enum value: UNDEFINED_LEGAL_ENTITY_TYPE = 0;
   */
  UNDEFINED_LEGAL_ENTITY_TYPE = 0,

  /**
   * @generated from enum value: SOLE_PROPIERTORSHIP = 1;
   */
  SOLE_PROPIERTORSHIP = 1,

  /**
   * @generated from enum value: LIMITED_LIABILITY_COMPANY = 2;
   */
  LIMITED_LIABILITY_COMPANY = 2,

  /**
   * @generated from enum value: CORPORATION = 3;
   */
  CORPORATION = 3,

  /**
   * @generated from enum value: LIMITED_PARTNERSHIP = 4;
   */
  LIMITED_PARTNERSHIP = 4,

  /**
   * @generated from enum value: PARTNERSHIP = 5;
   */
  PARTNERSHIP = 5,

  /**
   * @generated from enum value: TRUST = 6;
   */
  TRUST = 6,

  /**
   * @generated from enum value: OTHER = 7;
   */
  OTHER = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(CommercialCompanyInfo_LegalEntityType)
proto3.util.setEnumType(CommercialCompanyInfo_LegalEntityType, "proto.contact.CommercialCompanyInfo.LegalEntityType", [
  { no: 0, name: "UNDEFINED_LEGAL_ENTITY_TYPE" },
  { no: 1, name: "SOLE_PROPIERTORSHIP" },
  { no: 2, name: "LIMITED_LIABILITY_COMPANY" },
  { no: 3, name: "CORPORATION" },
  { no: 4, name: "LIMITED_PARTNERSHIP" },
  { no: 5, name: "PARTNERSHIP" },
  { no: 6, name: "TRUST" },
  { no: 7, name: "OTHER" },
]);

