import { ReactNode, useCallback, useEffect, useState } from 'react';

import { EmblaCarouselType } from 'embla-carousel';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';

import { Flex, Grid, Stack, styled } from '@agentero/styles/jsx';
import { Button, Text } from '@agentero/ui';

const SlideshowRoot = styled('div', {
	base: {
		position: 'relative',
		overflow: 'hidden',
		transition: `background-color .5s`,
		borderRadius: 'md',
		boxShadow: '4',
		userSelect: 'none'
	}
});

const SlideshowBody = styled('div', {
	base: {
		display: 'flex'
	}
});

const Slide = styled('div', {
	base: {
		display: 'flex',
		alignItems: 'center',
		color: 'white',
		flex: '0 0 100%',
		minWidth: '0',
		textWrap: 'balance'
	}
});

const SlideImage = styled('div', {
	base: {
		display: 'grid',
		placeContent: 'center',
		'& svg': {
			width: '5rem',
			height: '5rem',
			'& path': {
				fill: 'white'
			}
		}
	}
});

const SlideshowNav = styled('nav', {
	base: {
		display: 'flex',
		gap: '6',
		position: 'absolute',
		bottom: '8',
		left: '50%',
		transform: 'translateX(-50%)'
	}
});

const SlideshowNavButton = styled('button', {
	base: {
		position: 'relative',
		width: '24px',
		height: '24px',
		cursor: 'pointer',
		_before: {
			content: '""',
			width: '24px',
			height: '2px',
			backgroundColor: 'white',
			opacity: 0.5,
			position: 'absolute',
			top: 'calc(50% - 1px)',
			left: '0',
			transformOrigin: 'left center',
			borderRadius: '1px'
		}
	},
	variants: {
		active: {
			true: {
				_before: {
					opacity: 1
				}
			}
		}
	}
});

export type SlideshowItem = {
	id: string;
	title: string;
	body: string;
	image: ReactNode;
	cta: {
		label: string;
		action: () => void;
	};
	color: string;
};

type SlideshowProps = {
	items: SlideshowItem[];
	delay?: number;
};

export const Slideshow = ({ items, delay = 10000 }: SlideshowProps) => {
	const [emblaRef, emblaApi] = useEmblaCarousel({}, [Autoplay({ playOnInit: true, delay })]);
	const [color, setColor] = useState(items[0]?.color);

	const setBgColor = useCallback(
		(emblaApi: EmblaCarouselType) => {
			if (items.length) {
				const index = emblaApi.selectedScrollSnap() || 0;
				setColor(items[index].color);
			}
		},
		[items]
	);

	const onThumbClick = useCallback((index: number) => emblaApi?.scrollTo(index), [emblaApi]);

	useEffect(() => {
		emblaApi?.on('select', setBgColor);
	}, [emblaApi, setBgColor]);

	return !!items.length ? (
		<SlideshowRoot ref={emblaRef} style={{ backgroundColor: color }}>
			<SlideshowBody>
				{items.map(({ id, title, body, image, cta }) => (
					<Slide key={id}>
						<Grid columns={{ md: 2 }} flex="1" gridAutoRows="1fr">
							<Stack gap="24" paddingBlock="64" paddingInlineStart="64">
								<Stack gap="8">
									<Text size="title.subsection">
										<b>{title}</b>
									</Text>
									<Text size="body.small">{body}</Text>
								</Stack>
								<Flex mixBlendMode="lighten">
									<Button variant="tertiary" onClick={cta.action}>
										{cta.label}
									</Button>
								</Flex>
							</Stack>
							<SlideImage>{image}</SlideImage>
						</Grid>
					</Slide>
				))}
			</SlideshowBody>
			<SlideshowNav>
				{items.map(({ id }, index) => (
					<SlideshowNavButton
						key={id}
						onClick={() => onThumbClick(index)}
						active={emblaApi?.selectedScrollSnap() === index}
					/>
				))}
			</SlideshowNav>
		</SlideshowRoot>
	) : null;
};
