import { createPromiseClient } from '@connectrpc/connect';

import { PortalFrontendAPI } from '@agentero/service-clients/api';
import { LeadService } from '@agentero/service-clients/lead';
import { RaterService } from '@agentero/service-clients/rater';

import { portalTransport } from './connectClients/portalTransport';

export const leadService = createPromiseClient(LeadService, portalTransport);
export const raterService = createPromiseClient(RaterService, portalTransport);
export const portalService = createPromiseClient(PortalFrontendAPI, portalTransport);
