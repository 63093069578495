// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file svc/frontend/portal/proto/lead/lead.proto (package portal.lead, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { InsuranceType } from "../../../../../pkg/proto/policy/policy_pb";
import { Address } from "../../../../../pkg/proto/rater/rater_pb";
import { RaterForm } from "../../../../../pkg/proto/form/form_pb";

/**
 * @generated from message portal.lead.GetAgentInfoRequest
 */
export class GetAgentInfoRequest extends Message<GetAgentInfoRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetAgentInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "portal.lead.GetAgentInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgentInfoRequest {
    return new GetAgentInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgentInfoRequest {
    return new GetAgentInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgentInfoRequest {
    return new GetAgentInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgentInfoRequest | PlainMessage<GetAgentInfoRequest> | undefined, b: GetAgentInfoRequest | PlainMessage<GetAgentInfoRequest> | undefined): boolean {
    return proto3.util.equals(GetAgentInfoRequest, a, b);
  }
}

/**
 * @generated from message portal.lead.GetAgentInfoResponse
 */
export class GetAgentInfoResponse extends Message<GetAgentInfoResponse> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 2;
   */
  lastName = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string phone_number = 4;
   */
  phoneNumber = "";

  /**
   * @generated from field: string agency_name = 5;
   */
  agencyName = "";

  /**
   * @generated from field: string agency_image_url = 6;
   */
  agencyImageUrl = "";

  /**
   * @generated from field: string agent_image_url = 7;
   */
  agentImageUrl = "";

  constructor(data?: PartialMessage<GetAgentInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "portal.lead.GetAgentInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "agency_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "agency_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "agent_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAgentInfoResponse {
    return new GetAgentInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAgentInfoResponse {
    return new GetAgentInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAgentInfoResponse {
    return new GetAgentInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAgentInfoResponse | PlainMessage<GetAgentInfoResponse> | undefined, b: GetAgentInfoResponse | PlainMessage<GetAgentInfoResponse> | undefined): boolean {
    return proto3.util.equals(GetAgentInfoResponse, a, b);
  }
}

/**
 * @generated from message portal.lead.CreateLeadRiskRequest
 */
export class CreateLeadRiskRequest extends Message<CreateLeadRiskRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: proto.policy.InsuranceType insurance_type = 2;
   */
  insuranceType = InsuranceType.UNDEFINED_INSURANCE_TYPE;

  /**
   * @generated from field: map<string, string> rate_data = 3;
   */
  rateData: { [key: string]: string } = {};

  /**
   * @generated from field: proto.rater.Address address = 4;
   */
  address?: Address;

  /**
   * @generated from field: string additional_info = 5;
   */
  additionalInfo = "";

  /**
   * @generated from field: string phone_number = 6;
   */
  phoneNumber = "";

  /**
   * @generated from field: string email = 7;
   */
  email = "";

  constructor(data?: PartialMessage<CreateLeadRiskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "portal.lead.CreateLeadRiskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "insurance_type", kind: "enum", T: proto3.getEnumType(InsuranceType) },
    { no: 3, name: "rate_data", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 4, name: "address", kind: "message", T: Address },
    { no: 5, name: "additional_info", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateLeadRiskRequest {
    return new CreateLeadRiskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateLeadRiskRequest {
    return new CreateLeadRiskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateLeadRiskRequest {
    return new CreateLeadRiskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateLeadRiskRequest | PlainMessage<CreateLeadRiskRequest> | undefined, b: CreateLeadRiskRequest | PlainMessage<CreateLeadRiskRequest> | undefined): boolean {
    return proto3.util.equals(CreateLeadRiskRequest, a, b);
  }
}

/**
 * @generated from message portal.lead.GetLeadSchemaRequest
 */
export class GetLeadSchemaRequest extends Message<GetLeadSchemaRequest> {
  /**
   * @generated from field: proto.policy.InsuranceType insurance_type = 1;
   */
  insuranceType = InsuranceType.UNDEFINED_INSURANCE_TYPE;

  /**
   * @generated from field: string state = 2;
   */
  state = "";

  /**
   * @generated from field: string id = 3;
   */
  id = "";

  constructor(data?: PartialMessage<GetLeadSchemaRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "portal.lead.GetLeadSchemaRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "insurance_type", kind: "enum", T: proto3.getEnumType(InsuranceType) },
    { no: 2, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLeadSchemaRequest {
    return new GetLeadSchemaRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLeadSchemaRequest {
    return new GetLeadSchemaRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLeadSchemaRequest {
    return new GetLeadSchemaRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetLeadSchemaRequest | PlainMessage<GetLeadSchemaRequest> | undefined, b: GetLeadSchemaRequest | PlainMessage<GetLeadSchemaRequest> | undefined): boolean {
    return proto3.util.equals(GetLeadSchemaRequest, a, b);
  }
}

/**
 * @generated from message portal.lead.GetLeadSchemaResponse
 */
export class GetLeadSchemaResponse extends Message<GetLeadSchemaResponse> {
  /**
   * @generated from field: proto.form.RaterForm schema = 1;
   */
  schema?: RaterForm;

  constructor(data?: PartialMessage<GetLeadSchemaResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "portal.lead.GetLeadSchemaResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "schema", kind: "message", T: RaterForm },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLeadSchemaResponse {
    return new GetLeadSchemaResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLeadSchemaResponse {
    return new GetLeadSchemaResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLeadSchemaResponse {
    return new GetLeadSchemaResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetLeadSchemaResponse | PlainMessage<GetLeadSchemaResponse> | undefined, b: GetLeadSchemaResponse | PlainMessage<GetLeadSchemaResponse> | undefined): boolean {
    return proto3.util.equals(GetLeadSchemaResponse, a, b);
  }
}

/**
 * @generated from message portal.lead.ListLeadRisksRequest
 */
export class ListLeadRisksRequest extends Message<ListLeadRisksRequest> {
  /**
   * @generated from field: portal.lead.ListLeadRisksRequest.Filters filters = 1;
   */
  filters?: ListLeadRisksRequest_Filters;

  /**
   * @generated from field: portal.lead.ListLeadRisksRequest.OrderBy order_by = 2;
   */
  orderBy?: ListLeadRisksRequest_OrderBy;

  /**
   * @generated from field: portal.lead.ListLeadRisksRequest.Pagination pagination = 3;
   */
  pagination?: ListLeadRisksRequest_Pagination;

  constructor(data?: PartialMessage<ListLeadRisksRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "portal.lead.ListLeadRisksRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filters", kind: "message", T: ListLeadRisksRequest_Filters },
    { no: 2, name: "order_by", kind: "message", T: ListLeadRisksRequest_OrderBy },
    { no: 3, name: "pagination", kind: "message", T: ListLeadRisksRequest_Pagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListLeadRisksRequest {
    return new ListLeadRisksRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListLeadRisksRequest {
    return new ListLeadRisksRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListLeadRisksRequest {
    return new ListLeadRisksRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListLeadRisksRequest | PlainMessage<ListLeadRisksRequest> | undefined, b: ListLeadRisksRequest | PlainMessage<ListLeadRisksRequest> | undefined): boolean {
    return proto3.util.equals(ListLeadRisksRequest, a, b);
  }
}

/**
 * @generated from message portal.lead.ListLeadRisksRequest.Filters
 */
export class ListLeadRisksRequest_Filters extends Message<ListLeadRisksRequest_Filters> {
  /**
   * @generated from field: string search_text = 1;
   */
  searchText = "";

  /**
   * @generated from field: repeated proto.policy.InsuranceType insurance_types = 2;
   */
  insuranceTypes: InsuranceType[] = [];

  constructor(data?: PartialMessage<ListLeadRisksRequest_Filters>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "portal.lead.ListLeadRisksRequest.Filters";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "insurance_types", kind: "enum", T: proto3.getEnumType(InsuranceType), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListLeadRisksRequest_Filters {
    return new ListLeadRisksRequest_Filters().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListLeadRisksRequest_Filters {
    return new ListLeadRisksRequest_Filters().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListLeadRisksRequest_Filters {
    return new ListLeadRisksRequest_Filters().fromJsonString(jsonString, options);
  }

  static equals(a: ListLeadRisksRequest_Filters | PlainMessage<ListLeadRisksRequest_Filters> | undefined, b: ListLeadRisksRequest_Filters | PlainMessage<ListLeadRisksRequest_Filters> | undefined): boolean {
    return proto3.util.equals(ListLeadRisksRequest_Filters, a, b);
  }
}

/**
 * @generated from message portal.lead.ListLeadRisksRequest.OrderBy
 */
export class ListLeadRisksRequest_OrderBy extends Message<ListLeadRisksRequest_OrderBy> {
  /**
   * @generated from field: portal.lead.ListLeadRisksRequest.OrderBy.Field field = 1;
   */
  field = ListLeadRisksRequest_OrderBy_Field.UNSPECIFIED;

  /**
   * @generated from field: portal.lead.ListLeadRisksRequest.OrderBy.Direction direction = 2;
   */
  direction = ListLeadRisksRequest_OrderBy_Direction.UNSPECIFIED;

  constructor(data?: PartialMessage<ListLeadRisksRequest_OrderBy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "portal.lead.ListLeadRisksRequest.OrderBy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field", kind: "enum", T: proto3.getEnumType(ListLeadRisksRequest_OrderBy_Field) },
    { no: 2, name: "direction", kind: "enum", T: proto3.getEnumType(ListLeadRisksRequest_OrderBy_Direction) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListLeadRisksRequest_OrderBy {
    return new ListLeadRisksRequest_OrderBy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListLeadRisksRequest_OrderBy {
    return new ListLeadRisksRequest_OrderBy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListLeadRisksRequest_OrderBy {
    return new ListLeadRisksRequest_OrderBy().fromJsonString(jsonString, options);
  }

  static equals(a: ListLeadRisksRequest_OrderBy | PlainMessage<ListLeadRisksRequest_OrderBy> | undefined, b: ListLeadRisksRequest_OrderBy | PlainMessage<ListLeadRisksRequest_OrderBy> | undefined): boolean {
    return proto3.util.equals(ListLeadRisksRequest_OrderBy, a, b);
  }
}

/**
 * @generated from enum portal.lead.ListLeadRisksRequest.OrderBy.Field
 */
export enum ListLeadRisksRequest_OrderBy_Field {
  /**
   * @generated from enum value: FIELD_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: FIELD_CREATED_AT = 1;
   */
  CREATED_AT = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ListLeadRisksRequest_OrderBy_Field)
proto3.util.setEnumType(ListLeadRisksRequest_OrderBy_Field, "portal.lead.ListLeadRisksRequest.OrderBy.Field", [
  { no: 0, name: "FIELD_UNSPECIFIED" },
  { no: 1, name: "FIELD_CREATED_AT" },
]);

/**
 * @generated from enum portal.lead.ListLeadRisksRequest.OrderBy.Direction
 */
export enum ListLeadRisksRequest_OrderBy_Direction {
  /**
   * @generated from enum value: DIRECTION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DIRECTION_ASC = 1;
   */
  ASC = 1,

  /**
   * @generated from enum value: DIRECTION_DESC = 2;
   */
  DESC = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ListLeadRisksRequest_OrderBy_Direction)
proto3.util.setEnumType(ListLeadRisksRequest_OrderBy_Direction, "portal.lead.ListLeadRisksRequest.OrderBy.Direction", [
  { no: 0, name: "DIRECTION_UNSPECIFIED" },
  { no: 1, name: "DIRECTION_ASC" },
  { no: 2, name: "DIRECTION_DESC" },
]);

/**
 * @generated from message portal.lead.ListLeadRisksRequest.Pagination
 */
export class ListLeadRisksRequest_Pagination extends Message<ListLeadRisksRequest_Pagination> {
  /**
   * @generated from field: int64 offset = 1;
   */
  offset = protoInt64.zero;

  /**
   * @generated from field: int64 limit = 2;
   */
  limit = protoInt64.zero;

  constructor(data?: PartialMessage<ListLeadRisksRequest_Pagination>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "portal.lead.ListLeadRisksRequest.Pagination";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListLeadRisksRequest_Pagination {
    return new ListLeadRisksRequest_Pagination().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListLeadRisksRequest_Pagination {
    return new ListLeadRisksRequest_Pagination().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListLeadRisksRequest_Pagination {
    return new ListLeadRisksRequest_Pagination().fromJsonString(jsonString, options);
  }

  static equals(a: ListLeadRisksRequest_Pagination | PlainMessage<ListLeadRisksRequest_Pagination> | undefined, b: ListLeadRisksRequest_Pagination | PlainMessage<ListLeadRisksRequest_Pagination> | undefined): boolean {
    return proto3.util.equals(ListLeadRisksRequest_Pagination, a, b);
  }
}

/**
 * @generated from message portal.lead.ListLeadRisksResponse
 */
export class ListLeadRisksResponse extends Message<ListLeadRisksResponse> {
  /**
   * @generated from field: int64 total_count = 1;
   */
  totalCount = protoInt64.zero;

  /**
   * @generated from field: repeated portal.lead.LeadRisk lead_risks = 2;
   */
  leadRisks: LeadRisk[] = [];

  constructor(data?: PartialMessage<ListLeadRisksResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "portal.lead.ListLeadRisksResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "lead_risks", kind: "message", T: LeadRisk, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListLeadRisksResponse {
    return new ListLeadRisksResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListLeadRisksResponse {
    return new ListLeadRisksResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListLeadRisksResponse {
    return new ListLeadRisksResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListLeadRisksResponse | PlainMessage<ListLeadRisksResponse> | undefined, b: ListLeadRisksResponse | PlainMessage<ListLeadRisksResponse> | undefined): boolean {
    return proto3.util.equals(ListLeadRisksResponse, a, b);
  }
}

/**
 * @generated from message portal.lead.LeadRisk
 */
export class LeadRisk extends Message<LeadRisk> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 2;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: proto.policy.InsuranceType insurance_type = 4;
   */
  insuranceType = InsuranceType.UNDEFINED_INSURANCE_TYPE;

  /**
   * @generated from field: string first_name = 5;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 6;
   */
  lastName = "";

  /**
   * @generated from field: proto.rater.Address address = 7;
   */
  address?: Address;

  /**
   * @generated from field: string phone_number = 8;
   */
  phoneNumber = "";

  /**
   * @generated from field: string email = 9;
   */
  email = "";

  /**
   * @generated from field: string additional_info = 10;
   */
  additionalInfo = "";

  /**
   * @generated from field: string application_id = 11;
   */
  applicationId = "";

  /**
   * @generated from field: map<string, string> rate_data = 12;
   */
  rateData: { [key: string]: string } = {};

  constructor(data?: PartialMessage<LeadRisk>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "portal.lead.LeadRisk";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 4, name: "insurance_type", kind: "enum", T: proto3.getEnumType(InsuranceType) },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "address", kind: "message", T: Address },
    { no: 8, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "additional_info", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "application_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "rate_data", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LeadRisk {
    return new LeadRisk().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LeadRisk {
    return new LeadRisk().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LeadRisk {
    return new LeadRisk().fromJsonString(jsonString, options);
  }

  static equals(a: LeadRisk | PlainMessage<LeadRisk> | undefined, b: LeadRisk | PlainMessage<LeadRisk> | undefined): boolean {
    return proto3.util.equals(LeadRisk, a, b);
  }
}

/**
 * @generated from message portal.lead.GetLeadRiskByIDRequest
 */
export class GetLeadRiskByIDRequest extends Message<GetLeadRiskByIDRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetLeadRiskByIDRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "portal.lead.GetLeadRiskByIDRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLeadRiskByIDRequest {
    return new GetLeadRiskByIDRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLeadRiskByIDRequest {
    return new GetLeadRiskByIDRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLeadRiskByIDRequest {
    return new GetLeadRiskByIDRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetLeadRiskByIDRequest | PlainMessage<GetLeadRiskByIDRequest> | undefined, b: GetLeadRiskByIDRequest | PlainMessage<GetLeadRiskByIDRequest> | undefined): boolean {
    return proto3.util.equals(GetLeadRiskByIDRequest, a, b);
  }
}

/**
 * @generated from message portal.lead.GetLeadRiskByIDResponse
 */
export class GetLeadRiskByIDResponse extends Message<GetLeadRiskByIDResponse> {
  /**
   * @generated from field: portal.lead.LeadRisk lead_risk = 1;
   */
  leadRisk?: LeadRisk;

  constructor(data?: PartialMessage<GetLeadRiskByIDResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "portal.lead.GetLeadRiskByIDResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lead_risk", kind: "message", T: LeadRisk },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLeadRiskByIDResponse {
    return new GetLeadRiskByIDResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLeadRiskByIDResponse {
    return new GetLeadRiskByIDResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLeadRiskByIDResponse {
    return new GetLeadRiskByIDResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetLeadRiskByIDResponse | PlainMessage<GetLeadRiskByIDResponse> | undefined, b: GetLeadRiskByIDResponse | PlainMessage<GetLeadRiskByIDResponse> | undefined): boolean {
    return proto3.util.equals(GetLeadRiskByIDResponse, a, b);
  }
}

/**
 * @generated from message portal.lead.GetLeadFormURLRequest
 */
export class GetLeadFormURLRequest extends Message<GetLeadFormURLRequest> {
  constructor(data?: PartialMessage<GetLeadFormURLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "portal.lead.GetLeadFormURLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLeadFormURLRequest {
    return new GetLeadFormURLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLeadFormURLRequest {
    return new GetLeadFormURLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLeadFormURLRequest {
    return new GetLeadFormURLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetLeadFormURLRequest | PlainMessage<GetLeadFormURLRequest> | undefined, b: GetLeadFormURLRequest | PlainMessage<GetLeadFormURLRequest> | undefined): boolean {
    return proto3.util.equals(GetLeadFormURLRequest, a, b);
  }
}

/**
 * @generated from message portal.lead.GetLeadFormURLResponse
 */
export class GetLeadFormURLResponse extends Message<GetLeadFormURLResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<GetLeadFormURLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "portal.lead.GetLeadFormURLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLeadFormURLResponse {
    return new GetLeadFormURLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLeadFormURLResponse {
    return new GetLeadFormURLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLeadFormURLResponse {
    return new GetLeadFormURLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetLeadFormURLResponse | PlainMessage<GetLeadFormURLResponse> | undefined, b: GetLeadFormURLResponse | PlainMessage<GetLeadFormURLResponse> | undefined): boolean {
    return proto3.util.equals(GetLeadFormURLResponse, a, b);
  }
}

