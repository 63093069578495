export enum FeatureFlagKey {
	ApplicationsPage = 'applications-page',
	ComplianceWizard = 'compliance-wizard',
	NonQuotedCarriers = 'non-quoted-carriers',
	NotificationsPage = 'notifications-settings',
	CarriersPathWidget = 'carriers-path-widget',
	LossRatio = 'loss-ratio',
	BookSplit = 'book-split',
	LeadPage = 'lead-page',
	AppointedCarriersInDashboard = 'appointed-carriers-in-dashboard',
	CarriersTraining = 'carrier-trainings',
	CarrierAppointedModal = 'carrier-appointment-modal',
	DashboardSlideshow = 'dashboard-slideshow',
	PaymentIsFailed = 'payment-is-failed',
	BambooModalLogin = 'bamboo-log-in-modal',
	PieSlideshow = 'pie-slideshow',
	TierCampaignResult = 'tier-campaign-result'
}
