import { TierQualificationMode } from '@agentero/grpc-clients/grpc/shared/tier';

export enum MembershipType {
	Unspecified = 'unspecified',
	Subscription = 'subscription',
	PIF = 'pif'
}

const membershipTypeMap: { [key in TierQualificationMode]: MembershipType } = {
	[TierQualificationMode.TIER_QUALIFICATION_MODE_UNSPECIFIED]: MembershipType.Unspecified,
	[TierQualificationMode.TIER_QUALIFICATION_MODE_SUBSCRIPTION]: MembershipType.Subscription,
	[TierQualificationMode.TIER_QUALIFICATION_MODE_PIF]: MembershipType.PIF
};

export const parseMembershipType = (tierQualificationMode: TierQualificationMode): MembershipType =>
	membershipTypeMap[tierQualificationMode];
