// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file pkg/proto/rater/rater.proto (package proto.rater, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Carrier, QuotingMode } from "../carrier/carrier_pb";
import { Bundle, PolicyComponent } from "../policy/policy_pb";

/**
 * @generated from enum proto.rater.Rater
 */
export enum Rater {
  /**
   * @generated from enum value: UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: ITC_TURBORATER = 1;
   */
  ITC_TURBORATER = 1,

  /**
   * @generated from enum value: SEMSEE = 2;
   */
  SEMSEE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Rater)
proto3.util.setEnumType(Rater, "proto.rater.Rater", [
  { no: 0, name: "UNDEFINED" },
  { no: 1, name: "ITC_TURBORATER" },
  { no: 2, name: "SEMSEE" },
]);

/**
 * @generated from enum proto.rater.PremiumTerm
 */
export enum PremiumTerm {
  /**
   * @generated from enum value: ANNUAL = 0;
   */
  ANNUAL = 0,

  /**
   * @generated from enum value: SIX_MONTHS = 1;
   */
  SIX_MONTHS = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(PremiumTerm)
proto3.util.setEnumType(PremiumTerm, "proto.rater.PremiumTerm", [
  { no: 0, name: "ANNUAL" },
  { no: 1, name: "SIX_MONTHS" },
]);

/**
 * @generated from message proto.rater.Quote
 */
export class Quote extends Message<Quote> {
  /**
   * The id of the workitem that was created for this quote.
   *
   * @generated from field: string workitem_id = 1;
   */
  workitemId = "";

  /**
   * @generated from field: proto.carrier.Carrier carrier = 2;
   */
  carrier = Carrier.UNDEFINED;

  /**
   * @generated from field: repeated proto.policy.PolicyComponent components = 4;
   */
  components: PolicyComponent[] = [];

  /**
   * The URL to the quote page in the carrier portal,
   * for editing the quote and binding.
   *
   * @generated from field: string bind_url = 5;
   */
  bindUrl = "";

  /**
   * @generated from field: proto.rater.QuotePremium premium = 6;
   */
  premium?: QuotePremium;

  /**
   * @generated from field: optional proto.policy.Bundle bundle = 7;
   */
  bundle?: Bundle;

  /**
   * @generated from field: bool is_quote_proposal_allowed = 8;
   */
  isQuoteProposalAllowed = false;

  /**
   * @generated from field: bool retried = 9;
   */
  retried = false;

  /**
   * The URL from the Carrier with information about the quote.
   *
   * @generated from field: string summary_url = 10;
   */
  summaryUrl = "";

  /**
   * @generated from field: proto.carrier.QuotingMode quoting_mode = 11;
   */
  quotingMode = QuotingMode.NONE;

  /**
   * @generated from field: proto.rater.Rater rater = 12;
   */
  rater = Rater.UNDEFINED;

  /**
   * @generated from field: bool is_agentero_carrier = 13;
   */
  isAgenteroCarrier = false;

  /**
   * @generated from field: bool is_bindable_with_agentero = 14;
   */
  isBindableWithAgentero = false;

  /**
   * @generated from field: bool is_bind_with_agentero_requested = 15;
   */
  isBindWithAgenteroRequested = false;

  /**
   * @generated from field: string message = 16;
   */
  message = "";

  /**
   * @generated from field: optional string bind_request_id = 17;
   */
  bindRequestId?: string;

  constructor(data?: PartialMessage<Quote>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "proto.rater.Quote";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workitem_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
    { no: 4, name: "components", kind: "message", T: PolicyComponent, repeated: true },
    { no: 5, name: "bind_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "premium", kind: "message", T: QuotePremium },
    { no: 7, name: "bundle", kind: "message", T: Bundle, opt: true },
    { no: 8, name: "is_quote_proposal_allowed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "retried", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "summary_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "quoting_mode", kind: "enum", T: proto3.getEnumType(QuotingMode) },
    { no: 12, name: "rater", kind: "enum", T: proto3.getEnumType(Rater) },
    { no: 13, name: "is_agentero_carrier", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "is_bindable_with_agentero", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "is_bind_with_agentero_requested", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "bind_request_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Quote {
    return new Quote().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Quote {
    return new Quote().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Quote {
    return new Quote().fromJsonString(jsonString, options);
  }

  static equals(a: Quote | PlainMessage<Quote> | undefined, b: Quote | PlainMessage<Quote> | undefined): boolean {
    return proto3.util.equals(Quote, a, b);
  }
}

/**
 * @generated from message proto.rater.QuotePremium
 */
export class QuotePremium extends Message<QuotePremium> {
  /**
   * @generated from field: double amount = 1;
   */
  amount = 0;

  /**
   * @generated from field: proto.rater.PremiumTerm term = 2;
   */
  term = PremiumTerm.ANNUAL;

  constructor(data?: PartialMessage<QuotePremium>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "proto.rater.QuotePremium";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "term", kind: "enum", T: proto3.getEnumType(PremiumTerm) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuotePremium {
    return new QuotePremium().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuotePremium {
    return new QuotePremium().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuotePremium {
    return new QuotePremium().fromJsonString(jsonString, options);
  }

  static equals(a: QuotePremium | PlainMessage<QuotePremium> | undefined, b: QuotePremium | PlainMessage<QuotePremium> | undefined): boolean {
    return proto3.util.equals(QuotePremium, a, b);
  }
}

/**
 * @generated from message proto.rater.Address
 */
export class Address extends Message<Address> {
  /**
   * @generated from field: string street = 1;
   */
  street = "";

  /**
   * @generated from field: string street_number = 2;
   */
  streetNumber = "";

  /**
   * @generated from field: string zip_code = 3;
   */
  zipCode = "";

  /**
   * @generated from field: string city = 4;
   */
  city = "";

  /**
   * @generated from field: string state = 5;
   */
  state = "";

  /**
   * @generated from field: string unit = 6;
   */
  unit = "";

  /**
   * @generated from field: string county = 7;
   */
  county = "";

  constructor(data?: PartialMessage<Address>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "proto.rater.Address";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "street", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "street_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "unit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "county", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Address {
    return new Address().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Address {
    return new Address().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Address {
    return new Address().fromJsonString(jsonString, options);
  }

  static equals(a: Address | PlainMessage<Address> | undefined, b: Address | PlainMessage<Address> | undefined): boolean {
    return proto3.util.equals(Address, a, b);
  }
}

