// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file pkg/proto/tier/tier.proto (package proto.tier, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * Tier is an enum with the all the possible tiers.
 * TIER_UNSPECIFIED should be used when no tier information is available.
 *
 * @generated from enum proto.tier.Tier
 */
export enum Tier {
  /**
   * @generated from enum value: TIER_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TIER_BASIC = 1;
   */
  BASIC = 1,

  /**
   * @generated from enum value: TIER_STANDARD = 2;
   */
  STANDARD = 2,

  /**
   * @generated from enum value: TIER_ELITE = 3;
   */
  ELITE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Tier)
proto3.util.setEnumType(Tier, "proto.tier.Tier", [
  { no: 0, name: "TIER_UNSPECIFIED" },
  { no: 1, name: "TIER_BASIC" },
  { no: 2, name: "TIER_STANDARD" },
  { no: 3, name: "TIER_ELITE" },
]);

/**
 * TierQualificationMode tells how a tier was attained.
 *
 * @generated from enum proto.tier.TierQualificationMode
 */
export enum TierQualificationMode {
  /**
   * @generated from enum value: TIER_QUALIFICATION_MODE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * TIER_QUALIFICATION_MODE_SUBSCRIPTION is for when a tier is attained by
   * a paying subscription.
   *
   * @generated from enum value: TIER_QUALIFICATION_MODE_SUBSCRIPTION = 1;
   */
  SUBSCRIPTION = 1,

  /**
   * TIER_QUALIFICATION_MODE_PIF is for when a tier is attained by
   * a certain volume of Premium in Force.
   *
   * @generated from enum value: TIER_QUALIFICATION_MODE_PIF = 2;
   */
  PIF = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(TierQualificationMode)
proto3.util.setEnumType(TierQualificationMode, "proto.tier.TierQualificationMode", [
  { no: 0, name: "TIER_QUALIFICATION_MODE_UNSPECIFIED" },
  { no: 1, name: "TIER_QUALIFICATION_MODE_SUBSCRIPTION" },
  { no: 2, name: "TIER_QUALIFICATION_MODE_PIF" },
]);

/**
 * Campaign is an enum with the all the possible campaigns.
 *
 * @generated from enum proto.tier.Campaign
 */
export enum Campaign {
  /**
   * @generated from enum value: CAMPAIGN_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CAMPAIGN_TWO_POLICIES_MONTH = 1;
   */
  TWO_POLICIES_MONTH = 1,

  /**
   * @generated from enum value: CAMPAIGN_TWO_MONTHS_GRACE = 2;
   */
  TWO_MONTHS_GRACE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Campaign)
proto3.util.setEnumType(Campaign, "proto.tier.Campaign", [
  { no: 0, name: "CAMPAIGN_UNSPECIFIED" },
  { no: 1, name: "CAMPAIGN_TWO_POLICIES_MONTH" },
  { no: 2, name: "CAMPAIGN_TWO_MONTHS_GRACE" },
]);

/**
 * PaymentState is an enum with the all the possible payments.
 *
 * @generated from enum proto.tier.PaymentState
 */
export enum PaymentState {
  /**
   * @generated from enum value: PAYMENT_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PAYMENT_STATE_SUCCEEDED = 1;
   */
  SUCCEEDED = 1,

  /**
   * @generated from enum value: PAYMENT_STATE_FAILED = 2;
   */
  FAILED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(PaymentState)
proto3.util.setEnumType(PaymentState, "proto.tier.PaymentState", [
  { no: 0, name: "PAYMENT_STATE_UNSPECIFIED" },
  { no: 1, name: "PAYMENT_STATE_SUCCEEDED" },
  { no: 2, name: "PAYMENT_STATE_FAILED" },
]);

/**
 * Range is a range of values.
 *
 * @generated from message proto.tier.Range
 */
export class Range extends Message<Range> {
  /**
   * @generated from field: int32 from = 1;
   */
  from = 0;

  /**
   * @generated from field: int32 to = 2;
   */
  to = 0;

  constructor(data?: PartialMessage<Range>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "proto.tier.Range";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "to", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Range {
    return new Range().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Range {
    return new Range().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Range {
    return new Range().fromJsonString(jsonString, options);
  }

  static equals(a: Range | PlainMessage<Range> | undefined, b: Range | PlainMessage<Range> | undefined): boolean {
    return proto3.util.equals(Range, a, b);
  }
}

/**
 * TierDescription describes the requirements that have to be
 * attained to reach a tier.
 *
 * @generated from message proto.tier.TierDescription
 */
export class TierDescription extends Message<TierDescription> {
  /**
   * @generated from field: proto.tier.Tier tier = 1;
   */
  tier = Tier.UNSPECIFIED;

  /**
   * premium_in_force is the amount of premium in force that has to be attained
   *
   * @generated from field: proto.tier.Range premium_in_force = 2;
   */
  premiumInForce?: Range;

  /**
   * @generated from field: proto.tier.Range new_business = 3;
   */
  newBusiness?: Range;

  /**
   * @generated from field: proto.tier.Range renewal = 4;
   */
  renewal?: Range;

  /**
   * The id of the subscription plan in ChargeBee for this tier.
   *
   * @generated from field: string chargebee_id = 5;
   */
  chargebeeId = "";

  /**
   * The price to pay to subscribe to this tier.
   *
   * @generated from field: float subscription_price = 6;
   */
  subscriptionPrice = 0;

  /**
   * Annual price to pay to subscribe to this tier.
   *
   * @generated from field: float annual_subscription_price = 7;
   */
  annualSubscriptionPrice = 0;

  constructor(data?: PartialMessage<TierDescription>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "proto.tier.TierDescription";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tier", kind: "enum", T: proto3.getEnumType(Tier) },
    { no: 2, name: "premium_in_force", kind: "message", T: Range },
    { no: 3, name: "new_business", kind: "message", T: Range },
    { no: 4, name: "renewal", kind: "message", T: Range },
    { no: 5, name: "chargebee_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "subscription_price", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "annual_subscription_price", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TierDescription {
    return new TierDescription().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TierDescription {
    return new TierDescription().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TierDescription {
    return new TierDescription().fromJsonString(jsonString, options);
  }

  static equals(a: TierDescription | PlainMessage<TierDescription> | undefined, b: TierDescription | PlainMessage<TierDescription> | undefined): boolean {
    return proto3.util.equals(TierDescription, a, b);
  }
}

/**
 * @generated from message proto.tier.CurrentTier
 */
export class CurrentTier extends Message<CurrentTier> {
  /**
   * @generated from field: proto.tier.Tier tier = 1;
   */
  tier = Tier.UNSPECIFIED;

  /**
   * @generated from field: bool is_trial = 2;
   */
  isTrial = false;

  /**
   * @generated from field: int32 trial_days_left = 3;
   */
  trialDaysLeft = 0;

  /**
   * @generated from field: bool has_first_policy_sold = 4;
   */
  hasFirstPolicySold = false;

  /**
   * @generated from field: google.protobuf.Timestamp migration_active_at = 5;
   */
  migrationActiveAt?: Timestamp;

  /**
   * @generated from field: repeated proto.tier.CurrentTier.CampaignInfo campaigns = 6;
   */
  campaigns: CurrentTier_CampaignInfo[] = [];

  /**
   * @generated from field: optional proto.tier.PaymentState payment_state = 7;
   */
  paymentState?: PaymentState;

  /**
   * @generated from field: bool is_based_on_pif = 8;
   */
  isBasedOnPif = false;

  constructor(data?: PartialMessage<CurrentTier>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "proto.tier.CurrentTier";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tier", kind: "enum", T: proto3.getEnumType(Tier) },
    { no: 2, name: "is_trial", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "trial_days_left", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "has_first_policy_sold", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "migration_active_at", kind: "message", T: Timestamp },
    { no: 6, name: "campaigns", kind: "message", T: CurrentTier_CampaignInfo, repeated: true },
    { no: 7, name: "payment_state", kind: "enum", T: proto3.getEnumType(PaymentState), opt: true },
    { no: 8, name: "is_based_on_pif", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CurrentTier {
    return new CurrentTier().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CurrentTier {
    return new CurrentTier().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CurrentTier {
    return new CurrentTier().fromJsonString(jsonString, options);
  }

  static equals(a: CurrentTier | PlainMessage<CurrentTier> | undefined, b: CurrentTier | PlainMessage<CurrentTier> | undefined): boolean {
    return proto3.util.equals(CurrentTier, a, b);
  }
}

/**
 * @generated from message proto.tier.CurrentTier.CampaignInfo
 */
export class CurrentTier_CampaignInfo extends Message<CurrentTier_CampaignInfo> {
  /**
   * @generated from field: proto.tier.Campaign name = 1;
   */
  name = Campaign.UNSPECIFIED;

  /**
   * @generated from field: optional bool goal_reached = 2;
   */
  goalReached?: boolean;

  constructor(data?: PartialMessage<CurrentTier_CampaignInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "proto.tier.CurrentTier.CampaignInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "enum", T: proto3.getEnumType(Campaign) },
    { no: 2, name: "goal_reached", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CurrentTier_CampaignInfo {
    return new CurrentTier_CampaignInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CurrentTier_CampaignInfo {
    return new CurrentTier_CampaignInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CurrentTier_CampaignInfo {
    return new CurrentTier_CampaignInfo().fromJsonString(jsonString, options);
  }

  static equals(a: CurrentTier_CampaignInfo | PlainMessage<CurrentTier_CampaignInfo> | undefined, b: CurrentTier_CampaignInfo | PlainMessage<CurrentTier_CampaignInfo> | undefined): boolean {
    return proto3.util.equals(CurrentTier_CampaignInfo, a, b);
  }
}

