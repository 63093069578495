import { QualificationStatus as QualificationStatusConnect } from '@agentero/service-clients/api';

const bwaQualificationMappingConnect: Record<QualificationStatusConnect, boolean | undefined> = {
	[QualificationStatusConnect.UNSPECIFIED]: undefined,
	[QualificationStatusConnect.QUALIFIED]: true,
	[QualificationStatusConnect.PENDING_QUALIFICATION]: true,
	[QualificationStatusConnect.NOT_QUALIFIED]: false
};

export const parseBwAQualificationConnect = (
	bwaQualification: QualificationStatusConnect
): boolean | undefined => bwaQualificationMappingConnect[bwaQualification];
