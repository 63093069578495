// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts,import_extension=none"
// @generated from file svc/frontend/portal/proto/rater/rater.proto (package portal, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetApplicationDetailsRequest, GetApplicationDetailsResponse, GetCarrierApplicationsStatusRequest, GetCarrierApplicationsStatusResponse, GetRateDataRequest, GetRateDataResponse, GetRateResultsByRateIDRequest, GetRateResultsByRateIDResponse, GetRaterSchemaRequest, GetRaterSchemaResponse, GetRateStatisticsRequest, GetRateStatisticsResponse, GetSemseeRaterURLRequest, GetSemseeRaterURLResponse, ListRateApplicationsRequest, ListRateApplicationsResponse, LookUpRequest, LookUpResponse, PrefillRiskResponse, RateRequest, StreamingQuoteResponse } from "./rater_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service portal.RaterService
 */
export const RaterService = {
  typeName: "portal.RaterService",
  methods: {
    /**
     * @generated from rpc portal.RaterService.GetRaterSchema
     */
    getRaterSchema: {
      name: "GetRaterSchema",
      I: GetRaterSchemaRequest,
      O: GetRaterSchemaResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc portal.RaterService.GetRateResultsByRateID
     */
    getRateResultsByRateID: {
      name: "GetRateResultsByRateID",
      I: GetRateResultsByRateIDRequest,
      O: GetRateResultsByRateIDResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc portal.RaterService.GetApplicationDetails
     */
    getApplicationDetails: {
      name: "GetApplicationDetails",
      I: GetApplicationDetailsRequest,
      O: GetApplicationDetailsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc portal.RaterService.GetSemseeRaterURL
     */
    getSemseeRaterURL: {
      name: "GetSemseeRaterURL",
      I: GetSemseeRaterURLRequest,
      O: GetSemseeRaterURLResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc portal.RaterService.LookUp
     */
    lookUp: {
      name: "LookUp",
      I: LookUpRequest,
      O: LookUpResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Rate returns quotes for the given risk from carriers for which the
     * agent quoting is appointed to.
     * It works the same as the Quote RPC but expecting a different representation
     * of the risk being quoted.
     *
     * @generated from rpc portal.RaterService.Rate
     */
    rate: {
      name: "Rate",
      I: RateRequest,
      O: StreamingQuoteResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc portal.RaterService.PrefillRisk
     */
    prefillRisk: {
      name: "PrefillRisk",
      I: RateRequest,
      O: PrefillRiskResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetCarrierApplicationsStatus given a partial or completed rate request,
     * returns the application status for each quoting candidate carrier.
     *
     * @generated from rpc portal.RaterService.GetCarrierApplicationsStatus
     */
    getCarrierApplicationsStatus: {
      name: "GetCarrierApplicationsStatus",
      I: GetCarrierApplicationsStatusRequest,
      O: GetCarrierApplicationsStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetRate returns the rate for the given rate id.
     *
     * @generated from rpc portal.RaterService.GetRateData
     */
    getRateData: {
      name: "GetRateData",
      I: GetRateDataRequest,
      O: GetRateDataResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetRateStatistics
     *
     * @generated from rpc portal.RaterService.GetRateStatistics
     */
    getRateStatistics: {
      name: "GetRateStatistics",
      I: GetRateStatisticsRequest,
      O: GetRateStatisticsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * @generated from service portal.RateApplicationService
 */
export const RateApplicationService = {
  typeName: "portal.RateApplicationService",
  methods: {
    /**
     * @generated from rpc portal.RateApplicationService.ListRateApplications
     */
    listRateApplications: {
      name: "ListRateApplications",
      I: ListRateApplicationsRequest,
      O: ListRateApplicationsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

