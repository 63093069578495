// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file pkg/proto/binding/binding.proto (package proto.binding, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum proto.binding.PaymentPlan
 */
export enum PaymentPlan {
  /**
   * @generated from enum value: PAYMENT_PLAN_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PAYMENT_PLAN_MORTGAGEE_BILLED = 1;
   */
  MORTGAGEE_BILLED = 1,

  /**
   * @generated from enum value: PAYMENT_PLAN_EFT_ACH = 2;
   */
  EFT_ACH = 2,

  /**
   * @generated from enum value: PAYMENT_PLAN_CREDIT_CARD = 3;
   */
  CREDIT_CARD = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(PaymentPlan)
proto3.util.setEnumType(PaymentPlan, "proto.binding.PaymentPlan", [
  { no: 0, name: "PAYMENT_PLAN_UNSPECIFIED" },
  { no: 1, name: "PAYMENT_PLAN_MORTGAGEE_BILLED" },
  { no: 2, name: "PAYMENT_PLAN_EFT_ACH" },
  { no: 3, name: "PAYMENT_PLAN_CREDIT_CARD" },
]);

/**
 * @generated from enum proto.binding.BindRequestStatusCode
 */
export enum BindRequestStatusCode {
  /**
   * @generated from enum value: BIND_REQUEST_STATUS_UNSPECIFIED = 0;
   */
  BIND_REQUEST_STATUS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: BIND_REQUEST_STATUS_RECEIVED = 1;
   */
  BIND_REQUEST_STATUS_RECEIVED = 1,

  /**
   * @generated from enum value: BIND_REQUEST_STATUS_IN_REVIEW = 2;
   */
  BIND_REQUEST_STATUS_IN_REVIEW = 2,

  /**
   * @generated from enum value: BIND_REQUEST_STATUS_FINAL_QUOTE = 3;
   */
  BIND_REQUEST_STATUS_FINAL_QUOTE = 3,

  /**
   * @generated from enum value: BIND_REQUEST_STATUS_ISSUING = 4;
   */
  BIND_REQUEST_STATUS_ISSUING = 4,

  /**
   * BIND_REQUEST_STATUS_ISSUED means the policy is issued and the bind request is completed
   *
   * @generated from enum value: BIND_REQUEST_STATUS_ISSUED = 5;
   */
  BIND_REQUEST_STATUS_ISSUED = 5,

  /**
   * @generated from enum value: BIND_REQUEST_STATUS_CANCELLED = 6;
   */
  BIND_REQUEST_STATUS_CANCELLED = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(BindRequestStatusCode)
proto3.util.setEnumType(BindRequestStatusCode, "proto.binding.BindRequestStatusCode", [
  { no: 0, name: "BIND_REQUEST_STATUS_UNSPECIFIED" },
  { no: 1, name: "BIND_REQUEST_STATUS_RECEIVED" },
  { no: 2, name: "BIND_REQUEST_STATUS_IN_REVIEW" },
  { no: 3, name: "BIND_REQUEST_STATUS_FINAL_QUOTE" },
  { no: 4, name: "BIND_REQUEST_STATUS_ISSUING" },
  { no: 5, name: "BIND_REQUEST_STATUS_ISSUED" },
  { no: 6, name: "BIND_REQUEST_STATUS_CANCELLED" },
]);

/**
 * @generated from message proto.binding.BindingData
 */
export class BindingData extends Message<BindingData> {
  /**
   * @generated from field: proto.binding.AgentData agent_data = 1;
   */
  agentData?: AgentData;

  /**
   * @generated from field: string phone_number = 2;
   */
  phoneNumber = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string full_name = 4;
   */
  fullName = "";

  /**
   * @generated from field: string roof_year = 5;
   */
  roofYear = "";

  /**
   * @generated from field: google.protobuf.Timestamp purchase_date = 6;
   */
  purchaseDate?: Timestamp;

  /**
   * @generated from field: proto.binding.PaymentPlan desired_payment_plan = 7;
   */
  desiredPaymentPlan = PaymentPlan.UNSPECIFIED;

  /**
   * @generated from field: bool accept_terms = 8;
   */
  acceptTerms = false;

  /**
   * @generated from field: float final_premium = 9;
   */
  finalPremium = 0;

  /**
   * @generated from field: google.protobuf.Timestamp effective_date = 10;
   */
  effectiveDate?: Timestamp;

  /**
   * @generated from field: bool include_pip_coverage = 11;
   */
  includePipCoverage = false;

  /**
   * @generated from field: string policy_number = 12;
   */
  policyNumber = "";

  /**
   * @generated from field: string additional_information = 13;
   */
  additionalInformation = "";

  constructor(data?: PartialMessage<BindingData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "proto.binding.BindingData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agent_data", kind: "message", T: AgentData },
    { no: 2, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "roof_year", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "purchase_date", kind: "message", T: Timestamp },
    { no: 7, name: "desired_payment_plan", kind: "enum", T: proto3.getEnumType(PaymentPlan) },
    { no: 8, name: "accept_terms", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "final_premium", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 10, name: "effective_date", kind: "message", T: Timestamp },
    { no: 11, name: "include_pip_coverage", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "policy_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "additional_information", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BindingData {
    return new BindingData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BindingData {
    return new BindingData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BindingData {
    return new BindingData().fromJsonString(jsonString, options);
  }

  static equals(a: BindingData | PlainMessage<BindingData> | undefined, b: BindingData | PlainMessage<BindingData> | undefined): boolean {
    return proto3.util.equals(BindingData, a, b);
  }
}

/**
 * @generated from message proto.binding.AgentData
 */
export class AgentData extends Message<AgentData> {
  /**
   * @generated from field: string phone_number = 1;
   */
  phoneNumber = "";

  constructor(data?: PartialMessage<AgentData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "proto.binding.AgentData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AgentData {
    return new AgentData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AgentData {
    return new AgentData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AgentData {
    return new AgentData().fromJsonString(jsonString, options);
  }

  static equals(a: AgentData | PlainMessage<AgentData> | undefined, b: AgentData | PlainMessage<AgentData> | undefined): boolean {
    return proto3.util.equals(AgentData, a, b);
  }
}

